import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { Statistic } from '@b3w/react-statistic';
import { PrivacyPolicy } from '@b3w/pp-portal';
import '@b3w/pp-portal/dist/portal-bundle.css';
import s from './App.module.scss';
import api, { BASE_PATH, SERVICE_ALIAS } from '../../constants/api';
import Loader from '../Loader/Loader';
import Footer from '../Footer/Footer';
import { useVerifyQuery } from '../../generated/graphql';
import Header from '../Header/Header';
import MainPage from '../pages/MainPage/MainPage';
import InnerPage from '../pages/InnerPage/InnerPage';
import Page404 from '../pages/Page404/Page404';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import CardPage from '../pages/CardPage/CardPage';
import AddToHomeScreen from '../AddToHomeScreen/AddToHomeScreen';
import useFirebase from '../../hooks/useFirebase';

function App() {
    const [ppText, setPpText] = useState('');
    const { data, loading, error } = useVerifyQuery();

    useFirebase();

    useEffect(() => {
        axios.get(`${BASE_PATH}/text/${SERVICE_ALIAS}/privacyfooter/1`)
            .then((res) => {
                if (res.data) setPpText(res.data);
            })
            .catch(() => {});
    }, []);

    const [supportText, setSupportText] = useState('');

    useEffect(() => {
        axios.get(api.headerSupport).then((response) => {
            setSupportText(response.data);
        });
    }, []);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <ErrorPage />;
    }

    const verify = !!data?.point?.verify;
    const flowURL: string = data?.point?.flowURL ? data?.point?.flowURL : 'https://www.google.com/';

    return (
        <>
            {ppText && <PrivacyPolicy dangerousText={ppText} adoption />}
            <div className={s.wrapper}>
                <Statistic
                    path={`${BASE_PATH}/referer/statistic`}
                    timeoutMinutes={1}
                    from
                />
                <div
                    className={s.headerSupport}
                    dangerouslySetInnerHTML={{ __html: supportText }}
                />
                <Header />
                <Switch>
                    <Route path="/user/conditions">
                        <InnerPage url={api.offer} />
                    </Route>
                    <Route path="/privacypolicy">
                        <InnerPage url={api.privacypolicy} />
                    </Route>
                    <Route
                        path="/card_id/:id"
                        render={() => (
                            <CardPage
                                verify={verify}
                                flowURL={flowURL}
                            />
                        )}
                    />
                    <Route exact path="/error">
                        <ErrorPage />
                    </Route>
                    <Route exact path="/">
                        <MainPage />
                    </Route>
                    <Route>
                        <Page404 />
                    </Route>
                    <Loader />
                </Switch>
                <Footer verify={verify} />
                <AddToHomeScreen />
            </div>
        </>
    );
}

export default App;

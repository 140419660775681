import React, { useEffect, useState } from 'react';
import axios from 'axios';
import s from './InnerPage.module.scss';

type Props = {
    url: string,
};

const InnerPage = ({ url }: Props) => {
    const [text, setText] = useState('');

    useEffect(() => {
        setText('');

        axios.get(url)
            .then((res) => {
                if (res.status === 200) setText(res.data);
            })
            .catch(() => {});
    }, [url]);

    return (
        <div className={s.wrapper}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
};

export default InnerPage;
